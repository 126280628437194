import * as React from "react";
import './App.css';
import background from "./Assets/people.JPG";

class App extends React.Component {
  render() {
    return (
      <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', backgroundSize: 'cover' }}>
          <header className="App-header">
            <p style={{ color: '#fff', fontSize: 40, fontFamily: 'CormorantSCBold' }}>
              Welcome to
            </p>
            <p style={{ color: '#fff', fontSize: 60, fontFamily: 'CormorantSCBold', marginTop: 0 }}>
              The Layman cloob
            </p>
            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
              <button>Sign Up</button>
              <button>Sign In</button>
            </div>
          </header>
        </div>
      </div>
    );
  }
}
export default App;
